import { baseApi } from "./base";

import { CommonResponse } from "../../interfaces/common/response";
import { ContractFddAuth, UserFddAuth } from "../../interfaces/contract/fdd";
import { Pager } from "../../interfaces/common/common";
import { BatchSign, ContractList, ContractListSearchForm, IEnterpriseContractList, IEnterpriseContractListSearchForm, IEnterpriseContractQrcode, IEnterpriseContractVarsForm, IEnterpriseContractVarsInfo, IQueryEnterpriseContractSignStatus, ISendEnterpriseContractForm, ISignOfflineContractPollingResult, IUserSearchForm, IUserSearchList, SendContractForm } from "../../interfaces/contract/contract";
import { ContractTemplateDetail, ContractTemplateForm, ContractTemplateListSearchForm, ContractTemplateVars, Doc2Html, IContractTemplateList, IEnterpriseContractTemplateDetail, IEnterpriseContractTemplateForm } from "../../interfaces/contract/template";
import { ContractSetDetail, ContractSetForm, ContractSetListSearchForm, IContractSetList, IEnterpriseContractSetDetail, IEnterpriseContractSetForm } from "../../interfaces/contract/set";
import { DownloadUrl } from "../../interfaces/common/download";

const companyApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // 获取用户法大大权限
        getUserFddAuthInfo: builder.query<CommonResponse<UserFddAuth>, void>({
            query: () => ({ url: `/web/contract/staff_auth_info`, method: 'get' }),
        }),
        // 获取企业法大大权限
        getCompanyFddAuthInfo: builder.mutation<CommonResponse<ContractFddAuth>, void>({
            query: () => ({ url: `/web/contract/company_auth_info`, method: 'get' }),
        }),
        // 获取合同列表
        getMyContractList: builder.mutation<CommonResponse<IEnterpriseContractList>, IEnterpriseContractListSearchForm>({
            query: () => ({ url: `/web/contract/my_contracts`, method: 'get' }),
        }),
        // 批量签署合同
        batchSignContract: builder.mutation<CommonResponse<BatchSign>, { contract_ids: number[] }>({
            query: (form) => ({ url: `/web/contract/my_contracts`, method: 'get', params: form }),
        }),

        // /**
        //  * 合同模板（个人）
        //  */
        // // 获取合同模板列表
        // getContractTemplateList: builder.query<CommonResponse<IContractTemplateList>, ContractTemplateListSearchForm>({
        //     query: (form) => ({ url: `/web/contract/template_list`, method: 'get', params: form }),
        // }),
        // // 获取合同模板详情
        // getContractTemplateDetail: builder.mutation<CommonResponse<ContractTemplateDetail>, { id: number }>({
        //     query: (form) => ({ url: `/web/contract/template_detail`, method: 'get', params: form }),
        // }),
        // // 保存合同模板
        // saveContractTemplate: builder.mutation<CommonResponse, ContractTemplateForm>({
        //     query: (form) => ({ url: `/web/contract/template_save`, method: 'post', body: form }),
        // }),

        // // 删除合同模板
        // delContractTemplate: builder.mutation<CommonResponse, { id: number }>({
        //     query: (form) => ({ url: `/web/contract/template_delete`, method: 'get', params: form }),
        // }),




        /**
         * 合同模板（企业）
         */
        // 获取合同模板列表
        getEnterpriseContractTemplateList: builder.query<CommonResponse<IContractTemplateList>, ContractTemplateListSearchForm>({
            query: (form) => ({ url: `/web/contract_template/list`, method: 'post', body: form }),
        }),
        // 获取合同模板详情
        getEnterpriseContractTemplateDetail: builder.mutation<CommonResponse<IEnterpriseContractTemplateDetail>, { id: number }>({
            query: (form) => ({ url: `/web/contract_template/detail`, method: 'post', body: form }),
        }),
        // 保存合同模板
        saveEnterpriseContractTemplate: builder.mutation<CommonResponse, IEnterpriseContractTemplateForm>({
            query: (form) => ({ url: `/web/contract_template/save`, method: 'post', body: form }),
        }),
        // 删除合同模板
        delEnterpriseContractTemplate: builder.mutation<CommonResponse, { id: number }>({
            query: (form) => ({ url: `/web/contract_template/delete`, method: 'post', body: form }),
        }),
        // 获取合同模板变量
        getContractTemplateVars: builder.query<CommonResponse<ContractTemplateVars>, void>({
            query: () => ({ url: `/web/contract_template/vars`, method: 'get' }),
        }),
        // 导入合同doc生成html
        getHtmlFromWordFile: builder.mutation<CommonResponse<Doc2Html>, { file_id: number }>({
            query: (form) => ({ url: `/api/shares/doc2html`, method: 'get', params: form }),
        }),

        /**
         * 下发合同
         */
        // 获取下发的合同列表
        getContractList: builder.mutation<CommonResponse<ContractList>, ContractListSearchForm>({
            query: (form) => ({ url: `/web/contract/list`, method: 'get', params: form }),
        }),
        // 下发合同
        sendContract: builder.mutation<CommonResponse, SendContractForm>({
            query: (form) => ({ url: `/web/contract/make`, method: 'post', body: form }),
        }),
        // 合同预览
        previewContract: builder.mutation<CommonResponse<DownloadUrl>, { staff_id: number, template_id: number }>({
            query: (form) => ({ url: `/web/contract/preview`, method: 'post', body: form }),
        }),
        // 新合同预览
        renewalPreviewContract: builder.mutation<CommonResponse<DownloadUrl>, { staff_id: number, template_id: number, staff_contract_log_id: number }>({
            query: (form) => ({ url: `/web/staff/renewal_preview`, method: 'post', body: form }),
        }),
        // 下载合同
        downloadContract: builder.mutation<CommonResponse<DownloadUrl>, { staff_id: number, template_ids: number[] }>({
            query: (form) => ({ url: `/web/contract/download`, method: 'post', body: form }),
        }),
        // 新下载合同
        renewalDownloadContract: builder.mutation<CommonResponse<DownloadUrl>, { staff_contract_log_id: number }>({
            query: (form) => ({ url: `/web/staff/renewal_download`, method: 'post', body: form }),
        }),
        // 撤销合同
        revokeContract: builder.mutation<CommonResponse, { contract_ids: number[] }>({
            query: (form) => ({ url: `/web/contract/delete`, method: 'post', body: form }),
        }),

        /**
         * 单端签署
         */
        // 获取员工认证信息
        getStaffFddAuthInfo: builder.mutation<CommonResponse<UserFddAuth>, { staff_id: number }>({
            query: (form) => ({ url: `/web/contract_staff_sign/staff_auth_info`, method: 'post', body: form }),
        }),
        // 员工批量签约
        batchSignOfflineContract: builder.mutation<CommonResponse<BatchSign>, { staff_id: number, contract_ids: number[] }>({
            query: (form) => ({ url: `/web/contract_staff_sign/get_batch_sign_url`, method: 'post', body: form }),
        }),
        // 签署轮询
        signOfflineContractResultPolling: builder.mutation<CommonResponse<ISignOfflineContractPollingResult>, { contract_ids: number[] }>({
            query: (form) => ({ url: `/web/contract_staff_sign/query_party_sign_status`, method: 'post', body: form }),
        }),

        /**
         * 企业合同
         */
        // 获取企业合同列表
        getEnterpriseContractList: builder.mutation<CommonResponse<IEnterpriseContractList>, IEnterpriseContractListSearchForm>({
            query: (form) => ({ url: `/web/contract/list`, method: 'post', body: form }),
        }),
        // 撤销企业合同
        revokeEnterpriseContract: builder.mutation<CommonResponse, { contract_id: number }>({
            query: (form) => ({ url: `/web/contract/cancel`, method: 'post', body: form }),
        }),
        // 获取企业合同二维码
        getEnterpriseContractQrcode: builder.mutation<CommonResponse<IEnterpriseContractQrcode>, { party_id: number }>({
            query: (form) => ({ url: `/web/contract/get_qrcode`, method: 'post', body: form }),
        }),
        // 查询合同签署状态
        queryEnterpriseContractSignStatus: builder.mutation<CommonResponse<IQueryEnterpriseContractSignStatus>, { party_id: number }>({
            query: (form) => ({ url: `/web/contract/query_party_sign_status`, method: 'post', body: form }),
        }),
        // 获取可填写合同变量
        getEnterpriseContractVarsInfo: builder.mutation<CommonResponse<IEnterpriseContractVarsInfo>, { party_id: number }>({
            query: (form) => ({ url: `/web/contract/fill_info`, method: 'post', body: form }),
        }),
        // 填写合同变量
        saveEnterpriseContractVars: builder.mutation<CommonResponse, IEnterpriseContractVarsForm>({
            query: (form) => ({ url: `/web/contract/fill`, method: 'post', body: form }),
        }),
        // 下发合同
        sendEnterpriseContract: builder.mutation<CommonResponse, ISendEnterpriseContractForm>({
            query: (form) => ({ url: `/web/contract/make_party`, method: 'post', body: form }),
        }),

        // 用户管理搜索
        getSearchList: builder.mutation<CommonResponse<IUserSearchList>, IUserSearchForm>({
            query: (form) => ({ url: `/web/fdd_user/search`, method: 'post', body: form }),
        }),

    })
});

export const {
    useGetHtmlFromWordFileMutation,
    useGetUserFddAuthInfoQuery,
    useLazyGetUserFddAuthInfoQuery,
    useGetCompanyFddAuthInfoMutation,
    useGetMyContractListMutation,
    useBatchSignContractMutation,
    useGetContractListMutation,
    useSendContractMutation,
    usePreviewContractMutation,
    useRenewalDownloadContractMutation,
    useGetContractTemplateVarsQuery,
    useLazyGetContractTemplateVarsQuery,
    useDownloadContractMutation,
    useRenewalPreviewContractMutation,
    useRevokeContractMutation,
    useGetStaffFddAuthInfoMutation,
    useBatchSignOfflineContractMutation,
    useSignOfflineContractResultPollingMutation,
    useDelEnterpriseContractTemplateMutation,
    useGetEnterpriseContractTemplateDetailMutation,
    useGetEnterpriseContractTemplateListQuery,
    useLazyGetEnterpriseContractTemplateListQuery,
    useSaveEnterpriseContractTemplateMutation,
    useGetEnterpriseContractListMutation,
    useRevokeEnterpriseContractMutation,
    useGetEnterpriseContractQrcodeMutation,
    useQueryEnterpriseContractSignStatusMutation,
    useGetEnterpriseContractVarsInfoMutation,
    useSaveEnterpriseContractVarsMutation,
    useSendEnterpriseContractMutation,
    useGetSearchListMutation
} = companyApi;
