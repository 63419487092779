import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// antd
import { Col, Form, Input, message, Modal, Row, Select, Switch, Button, Descriptions, Tooltip, Tour, TourProps, Dropdown } from 'antd';

// apis
import { useGetContractTemplateVarsQuery, useGetEnterpriseContractTemplateDetailMutation, useGetHtmlFromWordFileMutation, useSaveEnterpriseContractTemplateMutation } from '../../../../store/apis/contract';
import { useGetDictListQuery, useUploadFileMutation } from '../../../../store/apis/common';

// interface
import { IEnterpriseContractTemplateForm } from '../../../../interfaces/contract/template';

// wang-editor
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'
import { CopyOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

interface CollectionCreateFormProps {
    data?: { id: number },
    visible: boolean;
    onFinish: () => void;
    onCancel: () => void;
}

function AddEditEnterpriseContractTemplate({
    visible,
    onFinish,
    onCancel,
    data
}: CollectionCreateFormProps) {
    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 签署类型
    const typeResult = useGetDictListQuery(['contract_sign_type'], { refetchOnMountOrArgChange: 60 });

    // 表单数据
    const [form] = Form.useForm<IEnterpriseContractTemplateForm>();
    const [detailTrigger, detailResult] = useGetEnterpriseContractTemplateDetailMutation();
    useEffect(() => {
        if (detailResult.isSuccess) {
            let data = detailResult.data.data.detail;
            // 初始化数据
            form.setFieldsValue({
                ...data,
            })
            setHtml(data.content);
        }
    }, [detailResult]);
    useEffect(() => {
        form.resetFields();
        setHtml('');

        // 初始化
        if (data?.id) {
            detailTrigger({ id: data.id });
        }
    }, [visible]);

    // 添加编辑
    const [saveTrigger, saveResult] = useSaveEnterpriseContractTemplateMutation();
    useEffect(() => {
        if (saveResult.isSuccess) {
            message.success(saveResult.data.msg);
            onFinish();
        }
    }, [saveResult]);

    // 提交
    const submit = async () => {
        setConfirmLoading(true);
        let _form = form.getFieldsValue();
        console.log(_form);

        let params = {
            ..._form,
            content: html
        }

        if (data?.id) {
            params['id'] = data.id;
        }

        await saveTrigger(params);

        setConfirmLoading(false);
    }

    // 合同变量
    const tempVarsResult = useGetContractTemplateVarsQuery(undefined, { refetchOnMountOrArgChange: 60, refetchOnReconnect: true });
    const [openTempVars, setOpenTempVars] = useState(false);
    const handleCancel = () => {
        setOpenTempVars(false);
    }
    // 上传点击框ref
    const inputRef = React.createRef<HTMLInputElement>();
    // 上传文件
    const [uploadTrigger, uploadResult] = useUploadFileMutation();
    useEffect(() => {
        if (uploadResult.isSuccess) {
            // 获取文件id传递给导入接口
            getHtmlTrigger({ file_id: uploadResult.data.data.file_id });
        }
    }, [uploadResult]);
    const upload = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        // 上传到服务器
        uploadTrigger({ file: event.target.files![0], type: 'contract_template' });
    };

    // 导入文档转换为html
    const [getHtmlTrigger, getHtmlResult] = useGetHtmlFromWordFileMutation();
    useEffect(() => {
        if (getHtmlResult.isSuccess) {
            setHtml(getHtmlResult.data.data.content);
        }
    }, [getHtmlResult]);

    // editor 实例
    const [editor, setEditor] = useState<IDomEditor | null>(null);
    // 编辑器内容
    const [html, setHtml] = useState('');
    // 工具栏配置
    const toolbarConfig: Partial<IToolbarConfig> = {
        toolbarKeys:
            [
                "headerSelect",
                "blockquote",
                "|",
                "bold",
                "underline",
                "italic",
                {
                    "key": "group-more-style",
                    "title": "更多",
                    "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M204.8 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z\"></path><path d=\"M505.6 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z\"></path><path d=\"M806.4 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z\"></path></svg>",
                    "menuKeys": [
                        "through",
                        "code",
                        "sup",
                        "sub",
                        "clearStyle"
                    ]
                },
                "color",
                "bgColor",
                "|",
                "fontSize",
                "fontFamily",
                "lineHeight",
                "|",
                "bulletedList",
                "numberedList",
                "todo",
                {
                    "key": "group-justify",
                    "title": "对齐",
                    "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z\"></path></svg>",
                    "menuKeys": [
                        "justifyLeft",
                        "justifyRight",
                        "justifyCenter",
                        "justifyJustify"
                    ]
                },
                {
                    "key": "group-indent",
                    "title": "缩进",
                    "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z\"></path></svg>",
                    "menuKeys": [
                        "indent",
                        "delIndent"
                    ]
                },
                "|",
                "insertTable",
                "divider",
                "|",
                "undo",
                "redo",
                "|",
                "fullScreen"
            ]
    };
    // 编辑器配置
    const editorConfig: Partial<IEditorConfig> = {
        placeholder: '请输入合同内容...',
    };
    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor]);

    return (
        <Modal
            width={1600}
            open={visible}
            title={<div>自定义合同</div>
            }
            okText="保存"
            cancelText="取消"
            maskClosable={false}
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            onOk={() => {
                console.log(form.getFieldsValue());
                form
                    .validateFields()
                    .then(values => {
                        console.log(values);
                        submit();
                    })
            }}
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Row>
                    <Col span={9}>
                        <Form.Item
                            label="合同名称"
                            name="title"
                            rules={[{ required: true, message: '请输入合同名称' }]}
                        >
                            <Input placeholder="请输入合同名称" maxLength={50} />
                        </Form.Item>


                        <Form.Item
                            label="签署类型"
                            name="sign_type"
                            rules={[{ required: true, message: '请选择签署类型' }]}
                        >
                            <Select
                                allowClear
                                placeholder="请选择签署类型"
                            >
                                {typeResult.data?.data.contract_sign_type?.options.map(option => (
                                    <Option key={option.value} value={option.value}>{option.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) => prevValues.sign_type !== curValues.sign_type}
                        >
                            {({ getFieldValue }) => {
                                const signType: number = Number(getFieldValue('sign_type') || 0);
                                return <>
                                    {
                                        signType >= 2
                                            ? <Form.Item
                                                label="甲方盖章关键字"
                                                name="party_a_sign_keyword"
                                                rules={[{ required: true, message: '请输入甲方盖章关键字' }]}
                                            >
                                                <Input placeholder="请输入甲方盖章关键字" maxLength={50} />
                                            </Form.Item>
                                            : <></>
                                    }

                                    {
                                        signType >= 1
                                            ? <Form.Item
                                                label="乙方盖章关键字"
                                                name="party_b_sign_keyword"
                                                rules={[{ required: true, message: '请输入乙方盖章关键字' }]}
                                            >
                                                <Input placeholder="请输入乙方盖章关键字" maxLength={50} />
                                            </Form.Item>
                                            : <></>
                                    }

                                    {
                                        signType >= 3
                                            ? <Form.Item
                                                label="丙方盖章关键字"
                                                name="party_c_sign_keyword"
                                                rules={[{ required: true, message: '请输入丙方盖章关键字' }]}
                                            >
                                                <Input placeholder="请输入丙方盖章关键字" maxLength={50} />
                                            </Form.Item>
                                            : <></>
                                    }

                                    {
                                        signType >= 4
                                            ? <Form.Item
                                                label="丁方盖章关键字"
                                                name="party_d_sign_keyword"
                                                rules={[{ required: true, message: '请输入丁方盖章关键字' }]}
                                            >
                                                <Input placeholder="请输入丁方盖章关键字" maxLength={50} />
                                            </Form.Item>
                                            : <></>
                                    }
                                </>;
                            }}
                        </Form.Item>


                    </Col>
                    <Col span={2}></Col>
                    <Col span={13}>
                        <Form.Item
                            label="内容"
                            name="content"
                        >
                            <Row style={{ 'marginBottom': 10 }}>
                                <Button
                                    style={{ 'marginRight': 10 }}
                                    onClick={() => setOpenTempVars(true)}
                                >合同变量表</Button>
                                <Button
                                    type='primary'
                                    onClick={() => {
                                        if (inputRef.current) {
                                            inputRef.current.value = '';
                                            inputRef.current.click();
                                        }
                                    }}
                                >上传合同文件</Button>
                            </Row>
                            <div style={{ border: '1px solid #ccc', zIndex: 100 }}>
                                <Toolbar
                                    editor={editor}
                                    defaultConfig={toolbarConfig}
                                    mode="default"
                                    style={{ borderBottom: '1px solid #ccc' }}
                                />
                                <Editor
                                    defaultConfig={editorConfig}
                                    value={html}
                                    onCreated={setEditor}
                                    onChange={editor => setHtml(editor.getHtml())}
                                    mode="simple"
                                    style={{ height: '500px', overflowY: 'hidden' }}
                                />
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Modal
                width={700}
                open={openTempVars}
                title="模板变量"
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        关闭
                    </Button>,
                ]}
            >
                <Descriptions bordered column={1} size="small">
                    {
                        tempVarsResult.data?.data.list.map(item => (
                            <Descriptions.Item label={item.desc}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>{item.name}</div>
                                    <Dropdown menu={{
                                        items: [{
                                            key: 'A',
                                            label: (
                                                <CopyToClipboard text={`\${A_${item.name}}`}
                                                    onCopy={() => message.success('已复制到剪切板')}>
                                                    <div>甲方</div>
                                                </CopyToClipboard>
                                            ),
                                        }, {
                                            key: 'B',
                                            label: (
                                                <CopyToClipboard text={`\${B_${item.name}}`}
                                                    onCopy={() => message.success('已复制到剪切板')}>
                                                    <div>乙方</div>
                                                </CopyToClipboard>
                                            ),
                                        }, {
                                            key: 'C',
                                            label: (
                                                <CopyToClipboard text={`\${C_${item.name}}`}
                                                    onCopy={() => message.success('已复制到剪切板')}>
                                                    <div>丙方</div>
                                                </CopyToClipboard>
                                            ),
                                        }, {
                                            key: 'D',
                                            label: (
                                                <CopyToClipboard text={`\${D_${item.name}}`}
                                                    onCopy={() => message.success('已复制到剪切板')}>
                                                    <div>丁方</div>
                                                </CopyToClipboard>
                                            ),
                                        }],
                                    }} placement="bottomRight">
                                        <Button type="text" icon={<CopyOutlined />}></Button>
                                    </Dropdown>
                                </div>
                            </Descriptions.Item>
                        ))
                    }
                </Descriptions>
            </Modal>

            {/* 导入上传input */}
            {
                <input
                    ref={inputRef}
                    style={{ display: 'none' }}
                    type="file"
                    accept='application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    onChange={upload}
                />
            }
        </Modal>
    );
}

export default AddEditEnterpriseContractTemplate;