import { useEffect, useState } from 'react';

// antd
import { Modal, Avatar, Form, message, Select, Button, Descriptions, List, Typography, Input } from 'antd';

// apis
import { useGetEnterpriseContractTemplateListQuery, useGetSearchListMutation, useSendEnterpriseContractMutation } from '../../../store/apis/contract';

// interface
import { ISendEnterpriseContractForm, ISendEnterpriseContractPartyForm, IUserSearchListData, SendContractForm } from '../../../interfaces/contract/contract';
import { IContractTemplateListData } from '../../../interfaces/contract/template';
import { useGetDictListQuery } from '../../../store/apis/common';
import { SearchProps } from 'antd/es/input';


const { Option } = Select;
const { Search } = Input;

interface CollectionCreateFormProps {
    data?: { staff_ids?: number[], template_ids?: number[] },
    visible: boolean;
    onCancel: () => void;
    onFinish: () => void;
}

const contractPartyTypeList = ['a', 'b', 'c', 'd'];
const initiatorTypeList = ['甲方', '乙方', '丙方', '丁方'];

const contractPartyTypeMap: Record<string, number> = {
    'a': 1,
    'b': 2,
    'c': 3,
    'd': 4
}

function SendEnterpriseContractModal({
    visible,
    onCancel,
    onFinish,
    data
}: CollectionCreateFormProps) {
    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<ISendEnterpriseContractForm>();

    // 初始化
    useEffect(() => {
        form.resetFields();
        if (data?.template_ids) {
            form.setFieldValue('template_ids', data.template_ids);
        }
    }, [visible]);

    // 签署类型
    const typeResult = useGetDictListQuery(['contract_sign_type', 'contract_party_type'], { refetchOnMountOrArgChange: 60 });

    // 合同列表
    const contractResult = useGetEnterpriseContractTemplateListQuery({ page: 1, size: 10000 }, { refetchOnMountOrArgChange: true });
    const [contractList, setContractList] = useState<IContractTemplateListData[]>([]);
    useEffect(() => {
        if (contractResult.isSuccess) {
            setContractList(contractResult.data!.data.list);
        }
    }, [contractResult]);

    // 提交
    const [sendTrigger, sendResult] = useSendEnterpriseContractMutation();
    useEffect(() => {
        if (sendResult.isSuccess) {
            message.success(sendResult.data.msg);
            onFinish();
        }
    }, [sendResult]);
    const submit = async () => {
        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        console.log(_form);

        for (let i = 0; i < _form.party_list.length; i++) {
            const item = _form.party_list[i];
            if (item.fdd_user_id === '') {
                message.warning('请选择合同方');
                setConfirmLoading(false);
                return;
            }
        }

        await sendTrigger({
            ..._form
        });

        setConfirmLoading(false);
    }

    // 搜索
    const [searchList, setSearchList] = useState<IUserSearchListData[]>([]);
    const [searchTrigger, searchResult] = useGetSearchListMutation();
    useEffect(() => {
        if (searchResult.isSuccess) {
            setSearchList(searchResult.data.data.list);
        }
    }, [searchResult]);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [nowSelect, setNowSelect] = useState<number>();
    const [searchAccountType, setSearchAccountType] = useState<1 | 2>(1);
    const onSearch = (value: string) => {
        searchTrigger({ account_type: searchAccountType, keywords: value });
    };


    return (
        <Modal
            width={1000}
            open={visible}
            title={`下发合同`}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    取消
                </Button>,
                <Button type="primary" loading={confirmLoading} onClick={() => {
                    form
                        .validateFields()
                        .then(values => {
                            console.log(values);
                            submit();
                        })
                }}>
                    下发
                </Button>,
            ]}
        >
            <Form form={form} layout='vertical'>
                <Form.Item
                    label="选择合同"
                    name="template_ids"
                    rules={[{ required: true, message: '请选择要下发的合同' }]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="请选择要下发的合同"
                        optionLabelProp="label"
                    >
                        {
                            contractList.map((contract) => (
                                <Option value={contract.id} label={contract.title}>
                                    {contract.title}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    label="合同签署类型"
                    name="sign_type"
                    rules={[{ required: true, message: '请选择合同签署类型' }]}
                >
                    <Select
                        onChange={(value) => {
                            if (contractPartyTypeMap[form.getFieldValue('initiator_type')] > value) {
                                form.resetFields(['initiator_type']);
                            }
                            let list: ISendEnterpriseContractPartyForm[] = form.getFieldValue('party_list') ?? [];
                            for (let i = 0; i < value; i++) {
                                list.push({
                                    name: "",
                                    fdd_user_id: "",
                                    party_type: ""
                                });
                            }
                            list.splice(value);
                            let initiatorType = form.getFieldValue('initiator_type');
                            list.forEach((item, index) => {
                                item.party_type = contractPartyTypeList[index];
                                item.name = initiatorTypeList[index] + ' - 待选择'
                                if (contractPartyTypeMap[initiatorType] - 1 === index) {
                                    item.name = initiatorTypeList[index] + ' - 本人';
                                    item.fdd_user_id = undefined;
                                }
                            });
                            form.setFieldValue('party_list', [...list]);
                        }}
                        allowClear
                        placeholder="请选择合同签署类型"
                    >
                        {typeResult.data?.data.contract_sign_type?.options.map(option => (
                            <Option key={option.value}>{option.name}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => prevValues.sign_type !== curValues.sign_type}
                >
                    {({ getFieldValue }) => {
                        const signType: number = getFieldValue('sign_type') ?? 0;

                        return (
                            <Form.Item
                                label="本人所属合同方"
                                name="initiator_type"
                                rules={[{ required: true, message: '请选择本人所属合同方' }]}
                            >
                                <Select
                                    allowClear
                                    placeholder="请选择本人所属合同方"
                                    onChange={(value) => {
                                        let list: ISendEnterpriseContractPartyForm[] = form.getFieldValue('party_list') ?? [];
                                        let index = contractPartyTypeMap[value] - 1;
                                        if (list.length > index) {
                                            list.forEach((item, i) => {
                                                if (i === index) {
                                                    list[index] = {
                                                        name: initiatorTypeList[i] + ' - 本人',
                                                        party_type: value
                                                    }
                                                } else {
                                                    item.name = initiatorTypeList[i] + ' - 待选择';
                                                    item.fdd_user_id = "";
                                                }
                                            })
                                            form.setFieldValue('party_list', [...list]);
                                        }

                                    }}
                                >
                                    {typeResult.data?.data.contract_party_type?.options.map(option => (
                                        <Option disabled={contractPartyTypeMap[option.value as unknown as string] > signType} key={option.value}>{option.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        );
                    }}
                </Form.Item>


                <Form.Item
                    label="选择下发对象"
                    required
                    shouldUpdate={(prevValues, curValues) => prevValues.party_list !== curValues.party_list}
                >
                    {({ getFieldValue }) => {
                        const partyList: ISendEnterpriseContractPartyForm[] = getFieldValue('party_list') ?? [];
                        return <div>
                            <List
                                style={{ marginTop: 10 }}
                                bordered
                                dataSource={partyList}
                                renderItem={(party, index) => (
                                    <List.Item>
                                        <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>{party.name}</div>
                                            {
                                                party.fdd_user_id !== undefined &&
                                                <Button type='link' onClick={() => {
                                                    setNowSelect(index);
                                                    setIsSearchOpen(true);
                                                }}>选择</Button>
                                            }
                                        </div>
                                    </List.Item>
                                )}
                            />
                        </div>
                    }}

                </Form.Item>
            </Form>

            <Modal
                open={isSearchOpen}
                onCancel={() => { setIsSearchOpen(false) }}
                footer={[
                    <Button key="back" onClick={onCancel}>
                        关闭
                    </Button>,
                ]}>
                <div style={{ margin: '30px 0 10px 0' }}>
                    <div style={{ display: 'flex' }}>
                        <Select value={searchAccountType} onChange={setSearchAccountType}>
                            <Option key={1} value={1}>个人</Option>
                            <Option key={2} value={2}>企业</Option>
                        </Select>
                        <Search placeholder="搜索手机号/姓名/企业名称" onSearch={onSearch} style={{ width: "100%", marginLeft: 5 }} />
                    </div>
                    <List
                        style={{ marginTop: 10 }}
                        bordered
                        dataSource={searchList}
                        renderItem={(party) => (
                            <List.Item>
                                <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{party.name}</div>
                                    <Button type='link' onClick={() => {
                                        const partyList: ISendEnterpriseContractPartyForm[] = form.getFieldValue('party_list') ?? [];
                                        partyList[nowSelect!].name = initiatorTypeList[nowSelect!] + ' - ' + party.name;
                                        partyList[nowSelect!].fdd_user_id = party.id;
                                        partyList[nowSelect!].account_type = searchAccountType;
                                        form.setFieldValue('party_list', [...partyList]);
                                        setIsSearchOpen(false);
                                    }}>选择</Button>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            </Modal>
        </Modal>
    );
}

export default SendEnterpriseContractModal;