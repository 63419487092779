import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

// antd
import { Button, Form, Input, message, Select } from 'antd';

// interface
import { RegisterForm } from '../../../interfaces/user/register';

// apis
import { useRegisterApplyMutation, useGetVCodeMutation } from '../../../store/apis/user';
import { useGetDictListQuery, useLazyGetIndustryListQuery } from '../../../store/apis/common';

// style
import '../login.scss';
import { LocationCascader } from '../../../components/form/locationCascader';
import './register.scss';
import { useGlobalCountDownSec } from '../../../store/utils/countdown';
import { codeType } from '../../../interfaces/common/code';

const { Option } = Select;

/**
 * 注册员工页
 */
function RegisterEnterprise() {
    // 路由
    const navigate = useNavigate();

    // 表单实例
    const [form] = Form.useForm<RegisterForm>();

    // 验证码冷却
    const [countdown, startCountdown] = useGlobalCountDownSec(60, 'getRegisterCode');
    // 获取验证码请求
    const [getCode, { data: getCodeRes, isLoading: getCodeLoading }] = useGetVCodeMutation();
    useEffect(() => {
        if (!getCodeRes) return;

        console.log(getCodeRes, '返回值');
        message.success('验证码短信已下发至您的手机');
    }, [getCodeRes]);

    // 获取验证码点击事件
    const onGetCodeButtonClick = async () => {
        try {
            const { mobile } = await form.validateFields(['mobile'])
            console.log(`mobile: ${mobile}`);
            // 开始倒计时
            startCountdown();
            // 发送请求
            getCode({ mobile, type: codeType.register });
        } catch (e) {
            console.log(e);
        }
    };

    // 获取验证码按钮
    const getCodeButton = () => (
        <Button
            type="text"
            loading={getCodeLoading || countdown > 0}
            onClick={onGetCodeButtonClick}
            style={{ color: 'white' }}
        >{countdown > 0 ? `${countdown}s` : '获取验证码'}</Button>
    );

    // 表单提交
    const [submitTrigger, submitResult] = useRegisterApplyMutation();
    useEffect(() => {
        if (submitResult.isSuccess) {
            message.success(submitResult.data.msg);
            navigate('/login', { replace: true });
        }
    }, [submitResult]);
    const onSubmit = async (values: RegisterForm) => {
        let _form = form.getFieldsValue(true);
        console.log('Received values of form: ', _form);
        submitTrigger({
            ..._form,
        })
    };

    // 籍贯
    const onNativeLocationChange = (value: number[]) => {
        form.setFieldsValue({
            native_province_id: value[0],
            native_city_id: value[1],
            native_area_id: value[2],
            native_street_id: value[3]
        });
    }

    const onLocationChange = (value: number[]) => {
        form.setFieldsValue({
            province_id: value[0],
            city_id: value[1],
            area_id: value[2],
            street_id: value[3]
        });
    }

    return (
        // 流式布局
        <div className='login-form register'>
            {/* 标题 */}
            <div className='login-form__title'>用户注册</div>

            {/* 表单 */}
            <Form
                form={form}
                name="registerForm"
                className="login-form__form"
                layout='vertical'
                onFinish={onSubmit}
            >
                <Form.Item
                    name="mobile"
                    label="手机号"
                    rules={[{ required: true, message: '请输入手机号' }]}
                >
                    <Input placeholder="请输入手机号" maxLength={11} />
                </Form.Item>

                <Form.Item
                    name="code"
                    label="验证码"
                    rules={[{ required: true, message: '请输入验证码' }]}
                >
                    <Input placeholder="请输入验证码" maxLength={20} addonAfter={
                        <Form.Item noStyle>
                            {getCodeButton()}
                        </Form.Item>
                    } />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="密码"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || value.length >= 6) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('密码长度不得小于6位'));
                            },
                        }),
                    ]}
                >
                    <Input.Password maxLength={20} />
                </Form.Item>

                <Form.Item
                    name="repassword"
                    label="再次输入密码"
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: '请再次输入密码',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('两次输入的密码不同'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="account_type"
                    label="注册身份"
                    rules={[{ required: true, message: '请选择注册身份' }]}
                >
                    <Select placeholder="请选择注册身份" options={[
                        { value: 1, label: '个人' },
                        { value: 2, label: '企业' },
                    ]} />
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => prevValues.account_type !== curValues.account_type}
                >
                    {({ getFieldValue }) => {
                        const accountType: 1 | 2 = getFieldValue('account_type');

                        switch (accountType) {
                            case 1:
                                return (
                                    <>
                                        <Form.Item
                                            name="real_name"
                                            label="姓名"
                                            rules={[{ required: true, message: '请输入姓名' }]}
                                        >
                                            <Input placeholder="请输入姓名" maxLength={10} />
                                        </Form.Item>

                                        <Form.Item
                                            name="idcard"
                                            label="身份证"
                                            rules={[{ required: true, message: '请输入身份证' }]}
                                        >
                                            <Input placeholder="请输入身份证" maxLength={18} />
                                        </Form.Item>

                                        <Form.Item
                                            name="native_ids"
                                            label="籍贯"
                                            rules={[{ required: true, message: '请选择籍贯' }]}
                                        >
                                            <LocationCascader deep={4} placeholder="请选择籍贯" onChange={onNativeLocationChange} />
                                        </Form.Item>

                                        <Form.Item
                                            name="native_address"
                                            label="籍贯地址"
                                            rules={[{ required: true, message: '请输入籍贯地址' }]}
                                        >
                                            <Input placeholder="请输入籍贯地址" maxLength={200} />
                                        </Form.Item>
                                    </>
                                );
                            case 2:
                                return (
                                    <>
                                        <Form.Item
                                            name="company_name"
                                            label="企业名称"
                                            rules={[{ required: true, message: '请输入企业名称' }]}
                                        >
                                            <Input placeholder="请输入企业名称" maxLength={50} />
                                        </Form.Item>

                                        <Form.Item
                                            label="统一社会信用代码"
                                            name="social_no"
                                            rules={[{ required: true, message: '请填写统一社会信用代码' }]}
                                        >
                                            <Input placeholder='统一社会信用代码' maxLength={18} />
                                        </Form.Item>

                                        <Form.Item
                                            name="location_ids"
                                            label="所在区域"
                                            rules={[{ required: true, message: '请选择所在区域' }]}
                                        >
                                            <LocationCascader deep={4} placeholder="请选择所在区域" onChange={onLocationChange} />
                                        </Form.Item>

                                        <Form.Item
                                            name="address"
                                            label="详细地址"
                                            rules={[{ required: true, message: '请输入详细地址' }]}
                                        >
                                            <Input placeholder="请输入详细地址" maxLength={200} />
                                        </Form.Item>

                                        <Form.Item
                                            name="legal_person"
                                            label="法人"
                                            rules={[{ required: true, message: '请输入法人名称' }]}
                                        >
                                            <Input placeholder="请输入法人名称" maxLength={10} />
                                        </Form.Item>

                                        <Form.Item
                                            name="legal_person_idcard"
                                            label="法人身份证号码"
                                            rules={[{ required: true, message: '请输入身份证' }]}
                                        >
                                            <Input placeholder="请输入身份证" maxLength={18} />
                                        </Form.Item>

                                        <Form.Item
                                            name="contact_name"
                                            label="联系人"
                                            rules={[{ required: true, message: '请输入联系人名称' }]}
                                        >
                                            <Input placeholder="请输入联系人名称" maxLength={10} />
                                        </Form.Item>

                                        <Form.Item
                                            name="contact_mobile"
                                            label="联系人手机号"
                                            rules={[{ required: true, message: '请输入联系人手机号' }]}
                                        >
                                            <Input placeholder="请输入联系人手机号" maxLength={11} />
                                        </Form.Item>
                                    </>
                                );
                        }

                    }}
                </Form.Item>

                {/* 登录按钮 */}
                <Form.Item>
                    <Button type="primary" htmlType="submit" block className="login-form__form__button" style={{ marginTop: 20 }}>
                        提交审核
                    </Button>
                </Form.Item>
            </Form>
        </div >
    );
}

export default RegisterEnterprise;