import { baseApi } from "./base";

import { CommonResponse } from "../../interfaces/common/response";
import { LoginCodeForm } from "../../interfaces/common/code";
import { LoginForm, LoginResult } from "../../interfaces/user/login";
import { AuthData, GroupAuthData } from "../../interfaces/user/auth";
import { ChangeMobileForm, IUserInfo, ResetPasswordForm, SuperAdmin, TransferAdministratorForm } from "../../interfaces/user/user";
import { RegisterForm, RegisterStaffForm } from "../../interfaces/user/register";
import { DictItem } from "../../interfaces/common/dict";


const usersApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // 登录
        login: builder.mutation<CommonResponse<LoginResult>, LoginForm>({
            query: (form) => ({ url: `/web/login`, method: 'post', body: form }),
            // transformResponse: (res: CommonResponse<LoginResult>, meta, arg) => {
            //     console.log(res, meta, arg);
            //     return res;
            // },
        }),
        // 获取登录验证码
        getVCode: builder.mutation<CommonResponse, LoginCodeForm>({
            query: (form) => ({ url: `/api/shares/sms_send`, method: 'post', body: form }),
        }),
        // 获取用户权限
        getAuth: builder.query<CommonResponse<AuthData>, { token: string }>({
            query: () => ({ url: `/web/perms/auth`, method: 'get' }),
        }),
        // 获取用户信息
        getUserInfo: builder.mutation<CommonResponse<IUserInfo>, void>({
            query: () => ({ url: `/web/init_data`, method: 'get' }),
        }),

        // 注册
        registerApply: builder.mutation<CommonResponse, RegisterForm>({
            query: (form) => ({ url: `/web/register`, method: 'post', body: form }),
        }),

        // 撤销企业申请
        revokeEnterpriseApply: builder.mutation<CommonResponse, void>({
            query: () => ({ url: `/web/cancel_company`, method: 'get' }),
        }),
        // 获取企业信息
        getCompanyInfoByCode: builder.query<CommonResponse<DictItem>, { invition_code: string }>({
            query: (form) => ({ url: `/web/company_info`, method: 'get', params: form }),
        }),
        // 检查手机号身份证重复
        checkingUserField: builder.mutation<CommonResponse, { keyword: string, type: string }>({
            query: (form) => ({ url: `/web/staff/check_working`, method: 'post', body: form }),
        }),
        // 更换手机号
        changeMobile: builder.mutation<CommonResponse, ChangeMobileForm>({
            query: (form) => {
                let body = {};
                switch (form.step) {
                    case 1:
                        body = {
                            old_mobile: form.old_mobile,
                            verify_code: form.verify_code
                        }
                        break;
                    case 2:
                        body = {
                            mobile: form.mobile,
                        }
                        break;
                    case 3:
                        body = {
                            mobile: form.mobile,
                            code: form.code
                        }
                        break;
                    default:
                        break;
                }

                return { url: `/web/user/change_mobile`, method: 'post', body: body };
            },
        }),
        // 更换密码
        resetPassword: builder.mutation<CommonResponse, ResetPasswordForm>({
            query: (form) => ({ url: `/web/pwd/alter`, method: 'post', body: form }),
        }),

        // 是否超管
        getIsSuperAdmin: builder.query<CommonResponse<SuperAdmin>, void>({
            query: () => ({ url: `/web/staff/get_is_super_admin`, method: 'get' }),
        }),

        // 转移管理员
        transferAdministrator: builder.mutation<CommonResponse, TransferAdministratorForm>({
            query: (form) => ({ url: `/web/user/transfer_super_admin`, method: 'post', body: form }),
        }),
    }),
});

export const {
    useLoginMutation,
    useGetVCodeMutation,
    useGetAuthQuery,
    useLazyGetAuthQuery,
    useGetUserInfoMutation,
    useRegisterApplyMutation,
    useRevokeEnterpriseApplyMutation,
    useGetCompanyInfoByCodeQuery,
    useLazyGetCompanyInfoByCodeQuery,
    useCheckingUserFieldMutation,
    useChangeMobileMutation,
    useResetPasswordMutation,
    useGetIsSuperAdminQuery,
    useLazyGetIsSuperAdminQuery,
    useTransferAdministratorMutation
} = usersApi;
