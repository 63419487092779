import { useEffect, useState } from 'react';

// antd
import { DescriptionsProps, Descriptions, Button } from 'antd';

// apis

// style
import './home.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useGetCompanyFddAuthInfoMutation, useLazyGetUserFddAuthInfoQuery } from '../../store/apis/contract';
import { useThrottleFn } from 'ahooks';
import { setFddAuth } from '../../store/user';
import { useNavigate } from 'react-router-dom';


/**
 * 主页
 */
function Home() {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const userInfo = useAppSelector((state) => state.user.userInfo);
    useEffect(() => {
        if (userInfo) {
            if (userInfo!.type) {
                loadFddAuth(userInfo.type);
            }
        } else {
            navigate('/login', { replace: true });
        }
    }, [userInfo])

    const [items, setItems] = useState<DescriptionsProps['items']>([
        {
            key: '1',
            label: '注册电子签',
            children: '正在加载信息',
        },
        // {
        //     key: '2',
        //     label: '开通自动签',
        //     children: '正在加载信息',
        // }
    ]);

    const { run: loadFddAuth } = useThrottleFn(
        (type: number) => {
            switch (type) {
                // 个人
                case 1:
                    userFddAuthTrigger().unwrap().then(res1 => {
                        setItems([
                            {
                                key: '1',
                                label: '注册电子签',
                                children: res1.data.is_auth ? '您已注册电子签功能' : <>
                                    <div>第一步：<Button type="link" onClick={() => window.open(res1.data.auth_url, '_blank')}>点击此处开通电子签</Button></div>
                                    <div>第二步：<Button type="link" onClick={() => loadFddAuth(type)} loading={fddAuthResult.isLoading}>点击刷新电子签状态</Button></div>
                                </>,
                            }
                        ])
                    });
                    break;

                // 企业
                case 2:
                    fddAuthTrigger().unwrap().then(res2 => {
                        setItems([
                            {
                                key: '1',
                                label: '注册电子签',
                                children: res2.data.is_auth ? '您已注册电子签功能' : <>
                                    <div>第一步：<Button type="link" onClick={() => window.open(res2.data.auth_url, '_blank')}>点击此处开通电子签</Button></div>
                                    <div>第二步：<Button type="link" onClick={() => loadFddAuth(type)} loading={fddAuthResult.isLoading}>点击刷新状态</Button></div>
                                </>,
                            },
                            {
                                key: '2',
                                label: '开通自动签',
                                children: res2.data.auto_sign ? '您已开通自动签功能' : <>
                                    <div>第一步：<Button type="link" onClick={() => window.open(res2.data.auto_sign_url, '_blank')}>点击此处开通自动签</Button></div>
                                    <div>第二步：<Button type="link" onClick={() => loadFddAuth(type)} loading={fddAuthResult.isLoading}>点击刷新状态</Button></div>
                                </>,
                            }
                        ])
                    });
                    break;
            }
        },
        { wait: 1000 },
    );

    const [fddAuthTrigger, fddAuthResult] = useGetCompanyFddAuthInfoMutation();
    const [userFddAuthTrigger, userFddAuthResult] = useLazyGetUserFddAuthInfoQuery();

    return (
        <div className="home inner-page">
            <div className='home__right'>
                <Descriptions title="注册信息" bordered items={items} />
            </div>
        </div>
    );
}

export default Home;
