import { createBrowserRouter, Navigate, Route, RouteObject } from "react-router-dom";
import DefaultLayout from "../layout/defaultLayout";
import { NothingPage } from "../view/common/nothing";
import { WaitingPage } from "../view/common/waiting";
import Home from "../view/home/home";
import Login from "../view/login/login";
import Mine from "../view/mine/mine";
import { NotFoundPage } from "../view/common/404";
import RegisterWizard from "../view/login/register/wizard";
import RegisterEnterprise from "../view/login/register/register";
import RegisterEnterprisePending from "../view/login/register/registerPending";
import ChangePasswordForm from "../view/mine/account/changePassword";
import { WarnPage } from "../view/common/warn";
import EnterpriseContractSendList from "../view/contract/enterpriseSend/contractSendList";
import ContractTemplate from "../view/contract/template/contractTemplate";
interface IRoute {
    path: string | undefined;
    name: string;
    element: JSX.Element;
    children?: IRoute[];
}

/**
 * 路由统一配置
 */
export const routes: IRoute[] = [
    {
        path: '/',
        name: '首页',
        element: <Navigate to={'/home'}></Navigate>,
    }, {
        path: '/home',
        name: '首页',
        element: <DefaultLayout><Home /></DefaultLayout>,

    }, {
        path: '/login',
        name: '登录',
        element: <Login />,
    }, {
        path: '/register',
        name: '注册',
        element: <RegisterWizard />,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <RegisterEnterprise />,
            },
        ]
    }, {
        path: 'changePassword',
        name: '修改密码',
        element: <DefaultLayout> <ChangePasswordForm /></DefaultLayout>,
    }, {
        path: '/contract',
        name: '合同列表',
        element: <DefaultLayout><EnterpriseContractSendList /></DefaultLayout>,
    }, {
        path: '/template',
        name: '合同模板',
        element: <DefaultLayout><ContractTemplate /></DefaultLayout>,
    }, {
        path: 'warn',
        name: '浏览器版本过低',
        element: <WarnPage />
    }, {
        path: '*',
        name: '无页面',
        element: <NotFoundPage />
    }
]

// 遍历路由树生成route列表
function getRouteList(routes: IRoute[] | undefined): RouteObject[] {
    if (!routes) return [];

    return routes.map(route => {
        let item: any = {
            path: route.path,
            element: route.element,
        };
        if (route.name === 'index') {
            item.index = true;
        } else {
            item.children = getRouteList(route.children);
        }
        return item;
    });
}

// 构建router
export const router = createBrowserRouter(getRouteList(routes));