import { Divider } from 'antd';
import React from 'react';
import BreadcrumbBar from '../components/breadcrumb/breadcrumb';
import NavigatorBar from '../components/navigator/navigator';
import NavigatorRowBar from '../components/navigator/navigatorRow';
import ShortcutAccess from '../components/shortcutAccess/shortcutAccess';

import './defaultLayout.scss'

function DefaultLayout({ children }: { children: React.ReactNode }) {
    return (
        <div>
            <div className='layout__right__top'>
                {/* 悬浮 */}
                <div className='layout__right__breadcrumb'>
                    {/* 面包屑 */}
                    {/* <BreadcrumbBar></BreadcrumbBar> */}
                    <h3 style={{ marginBottom: 0 }}>思博达合同管理平台</h3>
                    
                    <div style={{ flex: 1, marginLeft: 100 }}>
                        <NavigatorRowBar></NavigatorRowBar>
                    </div>

                    <ShortcutAccess></ShortcutAccess>
                </div>
            </div>
            <div className='default-layout'>
                {/* 左侧界面 */}
                {/* <div className='layout__left'> */}
                {/* 导航栏 */}
                {/* <NavigatorBar></NavigatorBar> */}
                {/* </div> */}
                {/* 右侧界面 */}
                <div className='layout__right'>

                    {/* 内容界面 */}
                    <div className='layout__right__children'>{children}</div>
                </div>
            </div>
        </div>
    );
}

export default DefaultLayout;