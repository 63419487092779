import { Outlet } from 'react-router-dom';

// assets
import bgImg from '@assets/login_bg.jpg';
import logo from '@assets/logo2.png';

// style
import '../login.scss';


/**
 * 注册员工页
 */
function RegisterWizard() {
    return (
        // 左右对称弹性布局
        <div className='login-box'>
            {/* 左边表单 */}
            <div className='login-box__left'>
                {/* 边距盒子 */}
                <div className='login-box__left__box'>
                    {/* logo */}
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <img className='login-box__left__box__logo' src={logo} alt="思博达logo" />
                        <p style={{ color: 'white', marginLeft: 10, marginBottom: 2, fontSize: 18 }}> | 合同管理平台</p>
                    </div>
                    {/* 间距 */}
                    <div className='login-box__left__box__padding'></div>
                    {/* 表单 */}
                    <Outlet />
                </div>
            </div>
            {/* 右边背景图片 */}
            <div className='login-box__right'>
                <img className='login-box__right__img' src={bgImg} alt="背景图片" />
            </div>
        </div>
    );
}


export default RegisterWizard;