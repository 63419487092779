import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearToken } from '../../../store/user';

// interface
import { codeType } from '../../../interfaces/common/code';
import { useGlobalCountDownSec } from '../../../store/utils/countdown';
import { ResetPasswordForm } from '../../../interfaces/user/user';

// apis
import { useGetVCodeMutation, useResetPasswordMutation } from '../../../store/apis/user';

// antd
import { Button, Form, message, Space, Input, Modal } from 'antd';


function ChangePasswordForm() {
    // redux方法
    const dispatch = useAppDispatch();
    // 路由
    const navigate = useNavigate();

    const [form] = Form.useForm<ResetPasswordForm>();

    // 发送验证码
    const [countdown, startCountdown] = useGlobalCountDownSec(60, 'getChangePasswordCode');
    const [sendVCodeTrigger, sendVCodeResult] = useGetVCodeMutation();
    useEffect(() => {
        if (sendVCodeResult.isSuccess) {
            // 开始倒计时
            startCountdown();
            message.success(sendVCodeResult.data.msg);
        }
    }, [sendVCodeResult]);
    const sendCode = () => {
        let mobile = form.getFieldValue('mobile');
        if (mobile) {
            // 请求
            sendVCodeTrigger({
                mobile: mobile,
                type: codeType.password
            })
        } else {
            message.warning('请先输入原本的手机号码');
        }
    };

    // 获取用户信息
    const userInfo = useAppSelector((state) => state.user.userInfo);
    form.setFieldValue('mobile', userInfo?.mobile);


    // 修改密码
    const [changePasswordTrigger, changePasswordResult] = useResetPasswordMutation();
    useEffect(() => {
        if (changePasswordResult.isSuccess) {
            message.success(changePasswordResult.data.msg);
            dispatch(clearToken());
            navigate("/login", { replace: true });
            Modal.info({
                title: '提示',
                content: '修改密码后需要重新登录',
            });
        }
    }, [changePasswordResult]);
    const changePassword = () => {
        form.validateFields().then(() => {
            let rawForm = form.getFieldsValue(true);
            changePasswordTrigger(rawForm);
        });
    }

    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>修改密码</div>
            </div>

            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                style={{ width: 500 }}
            >
                <Space style={{ display: 'flex' }}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) => prevValues.mobile !== curValues.mobile}
                    >
                        {({ getFieldValue }) => {
                            let mobile: string = getFieldValue('mobile');
                            let maskMobile = mobile.substring(0, 3) + '****' + mobile.substring(7);
                            return (
                                <Form.Item
                                    label="手机号"
                                    rules={[{ required: true, message: '请输入原本的手机号' }]}
                                    help="如果长时间未收到验证码，请检查是否将运营商拉黑"
                                >
                                    <Input readOnly value={maskMobile} />

                                </Form.Item>
                            );
                        }}
                    </Form.Item>

                    <Form.Item label=" ">
                        <Button
                            loading={sendVCodeResult.isLoading || countdown > 0}
                            onClick={sendCode}
                        >{countdown > 0 ? `${countdown}s` : '获取验证码'}</Button>
                    </Form.Item>
                </Space>

                <Form.Item
                    label="验证码"
                    name="code"
                    rules={[{ required: true, message: '请输入验证码' }]}
                >
                    <Input placeholder="请输入验证码" maxLength={11} />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="新密码"
                    rules={[
                        {
                            required: true,
                            message: '请输入新密码'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || value.length >= 6) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('密码长度不得小于6位'));
                            },
                        }),
                    ]}
                >
                    <Input.Password maxLength={20} />
                </Form.Item>

                <Form.Item
                    name="repassword"
                    label="再次输入密码"
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: '请再次输入密码',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('两次输入的密码不同'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Space>
                        <Button type='primary' onClick={changePassword}>提交表单</Button>
                    </Space>
                </Form.Item>
            </Form>
        </div >
    );
}


export default ChangePasswordForm;