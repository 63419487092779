import React, { useEffect, useRef, useState } from 'react';
import './login.scss';

// redux
import { useAppDispatch } from '../../store/hooks';
import { setTours } from '../../store/common';

// assets
import bgImg from '@assets/login_bg.jpg';
import logo from '@assets/logo2.png';

// antd
import { Button, Checkbox, Form, Input, message, Modal, Tour, TourProps } from 'antd';
import { LockOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons';

// model
import { LoginForm, loginType } from '../../interfaces/user/login';

// apis
import { useGetVCodeMutation, useGetUserInfoMutation, useLoginMutation } from '../../store/apis/user';
import { setToken, setUserInfo } from '../../store/user';
import { codeType } from '../../interfaces/common/code';
import { useGlobalCountDownSec } from '../../store/utils/countdown';
import { useNavigate } from 'react-router';
import { baseApi } from '../../store/apis/base';
import { rootStore } from '../../store/store';

/**
 * 登录页
 */
function Login() {
    return (
        // 左右对称弹性布局
        <div className='login-box'>
            {/* 左边表单 */}
            <div className='login-box__left'>
                {/* 边距盒子 */}
                <div className='login-box__left__box'>
                    {/* logo */}
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <img className='login-box__left__box__logo' src={logo} alt="思博达logo" />
                        <p style={{ color: 'white', marginLeft: 10, marginBottom: 2, fontSize: 18 }}> | 合同管理平台</p>
                    </div>
                    {/* 间距 */}
                    <div className='login-box__left__box__padding'></div>
                    {/* 表单 */}
                    <CompLoginForm />
                </div>
            </div>
            {/* 右边背景图片 */}
            <div className='login-box__right'>
                <div className='login-box__right__copyright'>Copyright@2023 by 思博达-合同管理平台-Powered By Siboda  <a href='http://beian.miit.gov.cn/' target="_blank">粤ICP备2020131149号</a></div>
                <img className='login-box__right__img' src={bgImg} alt="背景图片" />
            </div>
        </div>
    );
}

/**
 * 登录表单
 */
function CompLoginForm() {
    // redux方法
    const dispatch = useAppDispatch();

    // 路由
    const navigate = useNavigate();

    const [modal, contextHolder] = Modal.useModal();

    useEffect(() => {
        // 检测浏览器是否兼容新语法
        let isSelectorSupported;
        try {
            document.querySelector(':is(#foo, #bar)');
            isSelectorSupported = true;
        } catch (e) {
            isSelectorSupported = false;
        }

        if (!isSelectorSupported) {
            navigate('/warn', { replace: true });
        }
    }, [navigate]);


    // 表单实例
    const [form] = Form.useForm();

    /**
     * 1: 手机验证码登录(默认)
     * 2: 账号密码登录
     */
    const [loginWay, setLoginWay] = useState(loginType.code);

    // 登录请求
    const [login, loginRes] = useLoginMutation();
    // 获取用户信息
    const [userInfoTrigger, userInfoResult] = useGetUserInfoMutation();
    useEffect(() => {
        if (loginRes.isSuccess) {
            console.log(loginRes, '返回值');
            // 设置token
            dispatch(setToken(loginRes.data.data.token));

            // 重置全局请求缓存
            // 每次登录都会清空请求缓存，解放存储
            // 同时解决更新缓存问题
            dispatch(baseApi.util.resetApiState());

            setTimeout(() => {
                // 获取信息跳转
                userInfoTrigger().unwrap().then(res => {
                    dispatch(setUserInfo({
                        name: res.data.company?.company_name ?? res.data.personal?.real_name,
                        type: res.data.account_type,
                        mobile: res.data.mobile
                    }));
                    // 已入职直接跳转
                    navigate("/", { replace: true });
                });
            }, 100);
        }
    }, [loginRes]);


    // 验证码冷却
    const [countdown, startCountdown] = useGlobalCountDownSec(60, 'getLoginCode');

    // 获取验证码请求
    const [getCode, { data: getCodeRes, isLoading: getCodeLoading }] = useGetVCodeMutation();
    useEffect(() => {
        if (getCodeRes) {
            console.log(getCodeRes, '返回值');
            message.success(getCodeRes.msg);
        }
    }, [getCodeRes]);

    // 获取验证码点击事件
    const onGetCodeButtonClick = async () => {
        try {
            const { mobile } = await form.validateFields(['mobile'])
            console.log(`mobile: ${mobile}`);
            // 开始倒计时
            startCountdown();
            // 发送请求
            getCode({ mobile, type: codeType.login });
        } catch (e) {
            console.log(e);
        }
    };

    // 获取验证码按钮
    const getCodeButton = () => (
        <Button
            type="link"
            loading={getCodeLoading || countdown > 0}
            onClick={onGetCodeButtonClick}
        >{countdown > 0 ? `${countdown}s` : '获取验证码'}</Button>
    );

    // 表单提交
    const onSubmit = (values: LoginForm) => {
        values.login_type = loginWay;
        console.log('Received values of form: ', values);
        if (values.agreement) {
            login(values);
        } else {
            message.error('请先勾选同意用户协议');
        }
    };

    return (
        // 流式布局
        <div className='login-form'>
            {/* 标题（登录方式） */}
            <div className='login-form__title'>{loginWay === loginType.code ? '手机验证码登录' : '账号密码登录'}</div>

            {/* 表单 */}
            <Form
                form={form}
                name="login"
                className="login-form__form"
                initialValues={{ agreement: true }}
                onFinish={onSubmit}
            >
                {/* 手机号 */}
                <Form.Item
                    name="mobile"
                    rules={[{ required: true, message: '请输入手机号' }]}
                >
                    <Input prefix={<UserOutlined className="login-form__form__item-icon" size={50} />} placeholder="请输入手机号" />
                </Form.Item>

                {/* 不同登录方式 */}
                {
                    loginWay === loginType.code
                        // 验证码
                        ? <>
                            <Form.Item
                                name="code"
                                rules={[{ required: true, message: '请输入验证码' }]}
                            >
                                <Input
                                    prefix={<MessageOutlined className="login-form__form__item-icon" />}
                                    suffix={getCodeButton()}
                                    placeholder="请输入验证码"
                                />
                            </Form.Item>
                        </>
                        // 密码
                        : <Form.Item
                            name="password"
                            rules={[{ required: true, message: '请输入密码' }]}
                        >
                            <Input
                                prefix={<LockOutlined className="login-form__form__item-icon" />}
                                type="password"
                                placeholder="请输入密码"
                            />
                        </Form.Item>
                }

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type='link' onClick={() => { navigate('/register') }}>没有账号点击这里注册</Button >
                </div>

                {/* 间距 */}
                <div className='login-form__form__padding'></div>

                {/* 协议 */}
                <Form.Item>
                    <Form.Item name="agreement" valuePropName="checked" noStyle>
                        <Checkbox className='login-form__form__item-checkbox'>登录代表您已同意</Checkbox>
                    </Form.Item>

                    <a className="login-form__form__item-agreement" href="/#">
                        《用户协议》
                    </a>

                    <span className='login-form__form__item-checkbox'>、</span>

                    <a className="login-form__form__item-agreement" href="/#">
                        《隐私协议》
                    </a>
                </Form.Item>

                {/* 登录按钮 */}
                <Form.Item>
                    <Button loading={loginRes.isLoading} type="primary" htmlType="submit" block className="login-form__form__button">
                        立即登录
                    </Button>
                </Form.Item>

                {/* 切换登录方式 */}
                <Button
                    className='login-form__form__way-button'
                    type="link"
                    block
                    onClick={() => setLoginWay(loginWay === loginType.code ? loginType.password : loginType.code)}
                >
                    {loginWay === loginType.password ? '使用手机验证码登录' : '使用账号密码登录'}
                </Button>
            </Form>

            <>{contextHolder}</>
        </div>
    );
}
export default Login;