import React from 'react';
import { useEffect, useRef, useState } from 'react';

// antd
import { Space, Table, Dropdown, Menu, Select, Button, Modal, message, Input } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

// interface
import { ContractTemplateListSearchForm, IContractTemplateList, IContractTemplateListData } from '../../../interfaces/contract/template';

// apis
import { useDelEnterpriseContractTemplateMutation, useLazyGetEnterpriseContractTemplateListQuery } from '../../../store/apis/contract';

// utils 
import { useDebounce } from 'ahooks';

// assets
import { useGetDictListQuery } from '../../../store/apis/common';
import AddEditEnterpriseContractTemplate from './actions/addEditEnterpriseContractTemplate';


const { Column } = Table;
const { Option } = Select;
const { confirm } = Modal;

function ContractTemplate() {
    // 请求hook
    const [enterpriseListTrigger, enterpriseListResult] = useLazyGetEnterpriseContractTemplateListQuery();
    const loadData = (form: ContractTemplateListSearchForm) => {
        enterpriseListTrigger(form);
    }

    // 搜索表单
    const [searchForm, setSearchForm] = useState<ContractTemplateListSearchForm>({
        page: 1,
        size: 10,
    });
    const searchFormRef = useRef<ContractTemplateListSearchForm>(searchForm);
    useEffect(() => { searchFormRef.current = searchForm; }, [searchForm]);
    const [total, setTotal] = useState(0);

    // 数据填充
    const execLoadData = (resData: IContractTemplateList) => {
        console.log('不变的数据', searchFormRef.current);

        setTotal(resData.total);

        setListData((listData) => {
            let newListData = [...listData];
            // 初始化
            if (newListData.length !== resData.total) {
                newListData = new Array(resData.total);
                console.log('初始化', newListData);
            }
            // 更新局部数据
            newListData.splice((searchFormRef.current.page! - 1) * searchFormRef.current.size!, resData.list.length, ...resData.list);
            console.log('结果', newListData);
            return newListData;
        });
    }

    useEffect(() => {
        if (enterpriseListResult.isSuccess) {
            execLoadData(enterpriseListResult.data.data);
        }
    }, [enterpriseListResult]);

    // 分页
    const [listData, setListData] = useState<(IContractTemplateListData)[]>([])
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        setSearchForm((form) => {
            let newForm = {
                ...form,
                page,
                size: pageSize,
            };
            loadData(newForm);
            return newForm;
        })
    }

    // 类别（筛选）
    const cateResult = useGetDictListQuery(['contract_sign_type'], { refetchOnMountOrArgChange: 30 });
    // 事件
    const handleCateChange = (value: number) => {
        console.log(`selected ${value}`);
        let form = {
            ...searchFormRef.current,
            sign_type: value
        }
        setSearchForm(form)
        loadData(form);
    };

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(searchForm.keyword, { wait: 500 });
    useEffect(() => {
        loadData(searchForm);
    }, [keywordsDebouncedValue]);
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let form = {
            ...searchFormRef.current,
            keyword: value.target.value
        };
        setSearchForm(form);
    }

    // 重置表单
    const resetData = () => {
        let form: ContractTemplateListSearchForm = {
            ...searchFormRef.current,
            keyword: undefined,
            sign_type: undefined,
        }
        setSearchForm(form);
        loadData(form);
    }

    // 添加编辑模板弹窗
    const [addEditTemplateModalForm, setAddEditTemplateModalForm] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const toAddTemplate = () => {
        setAddEditTemplateModalForm({
            isModalVisible: true,
        })
    };
    const toEditTemplate = (item: IContractTemplateListData) => {
        setAddEditTemplateModalForm({
            isModalVisible: true,
            data: { id: item.id },
        })
    };

    // 删除
    const [delEnterpriseTrigger, delEnterpriseResult] = useDelEnterpriseContractTemplateMutation();
    useEffect(() => {
        if (delEnterpriseResult.isSuccess) {
            message.success(delEnterpriseResult.data.msg);
            loadData(searchFormRef.current);
        }
    }, [delEnterpriseResult]);
    const toDel = (id: number) => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '确定要删除此合同模板吗？',
            onOk() {
                delEnterpriseTrigger({ id: id });
            },
        });
    }

    // 下发合同弹窗
    const [sendContractModalForm, setSendContractModalForm] = useState<{
        isModalVisible: boolean,
        data?: { staff_ids?: number[], template_ids?: number[] },
    }>();
    const toSendContract = (template_id: number) => {
        setSendContractModalForm({
            isModalVisible: true,
            data: { template_ids: [template_id] }
        })
    };

    return (
        <>
            <div className='menu inner-page'>
                <div className='menu__title'>合同模板</div>

                {/* 顶部工作栏 */}
                <div className='search'>
                    <Select
                        allowClear
                        className="search__item"
                        placeholder="类别筛选"
                        value={searchForm.sign_type}
                        onChange={handleCateChange}
                    >
                        {cateResult.data?.data.contract_sign_type?.options.map(option => (
                            <Option key={option.value}>{option.name}</Option>
                        ))}
                    </Select>
                    <Input
                        className="search__item"
                        value={searchForm.keyword}
                        onChange={handleKeywordsChange}
                        placeholder="关键词搜索"
                    />
                    <Button
                        className="search__button"
                        icon={<CloseOutlined />}
                        loading={enterpriseListResult.isLoading}
                        onClick={resetData}
                    >
                        重置筛选
                    </Button>
                    <Button
                        type='primary'
                        className="search__last-button"
                        icon={<PlusOutlined />}
                        onClick={toAddTemplate}
                    >
                        添加合同模板
                    </Button>
                </div>

                {/* 表格 */}
                <Table
                    dataSource={listData}
                    size='small'
                    loading={enterpriseListResult.isLoading}
                    rowKey={(record: IContractTemplateListData) => record?.id}
                    pagination={{
                        position: ['bottomRight'],
                        size: "small",
                        total: total,
                        showTotal: total => `共 ${total} 项`,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        onChange: onPageChange
                    }}>
                    <Column title="合同名称" dataIndex="title" key="title" />
                    <Column title="合同签署类型" dataIndex="sign_type_text" key="sign_type_text" />
                    <Column title="修改时间" dataIndex="updated_at" key="updated_at" />
                    <Column
                        title="操作"
                        key="action"
                        render={(_: any, record: IContractTemplateListData | undefined) => (
                            <Space>
                                <Dropdown.Button trigger={['click', 'hover']} type="primary" loading={false} overlay={
                                    <Menu
                                        items={record ? [
                                            {
                                                key: 'edit',
                                                label: (
                                                    <div>编辑</div>
                                                ),
                                                onClick: () => toEditTemplate(record),
                                            },
                                            {
                                                key: 'del',
                                                label: (
                                                    <div>删除</div>
                                                ),
                                                onClick: () => toDel(record.id),
                                            },
                                        ]
                                            : []}
                                    />
                                }>
                                    操作
                                </Dropdown.Button>
                            </Space>
                        )}
                    />
                </Table>
            </div>


            {/* 编辑模板弹窗 */}
            {
                addEditTemplateModalForm
                    ? <AddEditEnterpriseContractTemplate
                        data={addEditTemplateModalForm.data}
                        visible={addEditTemplateModalForm.isModalVisible}
                        onFinish={() => {
                            setAddEditTemplateModalForm({
                                ...addEditTemplateModalForm,
                                isModalVisible: false
                            })
                            loadData(searchFormRef.current);
                        }}
                        onCancel={() => {
                            setAddEditTemplateModalForm({
                                ...addEditTemplateModalForm,
                                isModalVisible: false
                            })
                        }}
                    />
                    : null
            }
        </>
    );
}

export default ContractTemplate;