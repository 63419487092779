// react
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

// style
import './index.css';
import '@assets/style/variable.scss';
import '@assets/style/style.scss';
import 'material-icons/iconfont/filled.css';

// routes
import RoutesLayout from './routes';

// redux
import { Provider } from 'react-redux';
import { rootStore, persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';

// antd国际化
import { ConfigProvider } from 'antd';
import 'dayjs/locale/zh-cn';
import locale from 'antd/locale/zh_CN';
import dayjs from './utils/dayjs';

dayjs.locale('zh-cn');

// 清除之前的缓存
localStorage.removeItem('persist:root');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // antd全局配置
    <ConfigProvider locale={locale}>
        {/* redux */}
        <Provider store={rootStore}>
            {/* persist */}
            <PersistGate persistor={persistor}>
                {/* 路由 */}
                <RoutesLayout />
            </PersistGate>
        </Provider>
    </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
