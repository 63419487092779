import { createSlice } from '@reduxjs/toolkit';
import { UserFddAuth } from '../interfaces/contract/fdd';

interface UserState {
    token: string;
    userInfo?: {
        mobile: string,
        name: string,
        type: number,
    },
    fddAuth?: UserFddAuth
}

const initialState: UserState = {
    token: '',
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearToken: (state: UserState) => {
            state.token = '';
        },
        setToken: (state: UserState, action) => {
            state.token = action.payload;
        },
        setUserInfo: (state: UserState, action) => {
            state.userInfo = action.payload;
        },
        clearUserInfo: (state: UserState) => {
            state.userInfo = undefined;
        },
        setFddAuth: (state: UserState, action) => {
            state.fddAuth = action.payload;
        }

    },
    extraReducers: {},
});

export const { clearToken, setToken, setUserInfo, clearUserInfo, setFddAuth } = userSlice.actions