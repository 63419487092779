import { useEffect, useRef, useState } from 'react';

// antd
import { Space, Table, Dropdown, Menu, Select, Button, Modal, message, Input, Radio, RadioChangeEvent, Tabs, Form } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined, SendOutlined } from '@ant-design/icons';

// interface
import { IEnterpriseContractListData, IEnterpriseContractListSearchForm, IEnterpriseContractVarItem, IEnterpriseContractVarsForm } from '../../../interfaces/contract/contract';

// apis
import { useGetEnterpriseContractListMutation, useGetEnterpriseContractVarsInfoMutation, useGetStaffFddAuthInfoMutation, useQueryEnterpriseContractSignStatusMutation, useRevokeContractMutation, useRevokeEnterpriseContractMutation, useSaveEnterpriseContractVarsMutation, useSignOfflineContractResultPollingMutation } from '../../../store/apis/contract';

// assets
import SendContractModal from './sendContract';
import { useAppSelector } from '../../../store/hooks';


const { Column } = Table;
const { Option } = Select;
const { confirm } = Modal;

function EnterpriseContractSendList() {
    // 获取法大大权限
    const fddAuth = useAppSelector((state) => state.user.fddAuth);

    // 请求hook
    const [listTrigger, listResult] = useGetEnterpriseContractListMutation();
    useEffect(() => {
        listTrigger(searchFormRef.current);
    }, []);

    // 搜索表单
    const [searchForm, setSearchForm] = useState<IEnterpriseContractListSearchForm>({
        page: 1,
        size: 10,
        nav_type: 1
    });
    const searchFormRef = useRef<IEnterpriseContractListSearchForm>(searchForm);
    useEffect(() => { searchFormRef.current = searchForm; }, [searchForm]);

    // 数据填充
    useEffect(() => {
        if (listResult.isSuccess) {
            console.log('不变的数据', searchFormRef.current);

            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== listResult.data.data.total) {
                    newListData = new Array(listResult.data.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((searchFormRef.current.page! - 1) * searchFormRef.current.size!, listResult.data.data.list.length, ...listResult.data.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        }
    }, [listResult]);

    // 分页
    const [listData, setListData] = useState<(IEnterpriseContractListData)[]>([])
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        setSearchForm((form) => {
            let newForm = {
                ...form,
                page,
                size: pageSize,
            };
            listTrigger(newForm);
            return newForm;
        })
    }

    // table选中
    // const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    // const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    //     console.log('selectedRowKeys changed: ', selectedRowKeys);
    //     setSelectedRowKeys(newSelectedRowKeys);
    // };

    const [revokeTrigger, revokeResult] = useRevokeEnterpriseContractMutation();
    useEffect(() => {
        if (revokeResult.isSuccess) {
            message.success(revokeResult.data.msg);
            listTrigger(searchFormRef.current);
        }
    }, [revokeResult, listTrigger]);
    const toRevoke = (id: number) => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: `确定要撤销合同吗?`,
            onOk() {
                // setSelectedRowKeys([]);
                return new Promise((resolve, reject) => {
                    revokeTrigger({
                        contract_id: id,
                    }).then(resolve).catch(reject);
                }).catch(() => console.log('Oops errors!'));
            },
        });
    }

    // 下发合同弹窗
    const [sendContractModalForm, setSendContractModalForm] = useState<{
        isModalVisible: boolean,
        data?: { staff_ids?: number[], template_ids?: number[] },
    }>();
    const toSendContract = () => {
        setSendContractModalForm({
            isModalVisible: true,
        })
    };

    // type筛选
    const handleStatusChange = (e: RadioChangeEvent) => {
        let form = {
            ...searchFormRef.current,
            nav_type: e.target.value
        };
        setSearchForm(form);
        listTrigger(form);
    }

    // 排序
    const handleSortChange = (value: string) => {
        let form: IEnterpriseContractListSearchForm = {
            ...searchFormRef.current,
        }
        setSearchForm(form)
        listTrigger(form);
    };

    // 签署
    const [querySignStatusTrigger, querySignStatusResult] = useQueryEnterpriseContractSignStatusMutation();
    const toSign = (contract: IEnterpriseContractListData) => {
        if (contract.sign_url) {
            window.open(contract.sign_url, '_blank');
        }

        let count = 3;
        let doQuery = (resolve: (value: unknown) => void, reject: (reason?: any) => void) => {
            count -= 1;
            setTimeout(() => {
                querySignStatusTrigger({ party_id: contract.company_party_id }).unwrap().then(res => {
                    if (!res.data.detail.is_sign && count > 0) {
                        doQuery(resolve, reject);
                    } else {
                        resolve('ok');
                        listTrigger(searchFormRef.current);
                    }
                }).catch(e => {
                    reject(e);
                });
            }, 2000);
        }

        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否已完成签署？',
            cancelText: '还未完成',
            okText: '我已完成',
            onOk() {
                return new Promise((resolve, reject) => {
                    doQuery(resolve, reject);
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }


    // 填写合同参数
    const [form] = Form.useForm<IEnterpriseContractVarsForm>();
    const [contractFormModalForm, setContractFormModalForm] = useState<{
        isModalVisible: boolean,
    }>({
        isModalVisible: false,
    });
    const [getVarsTrigger, getVarsResult] = useGetEnterpriseContractVarsInfoMutation();
    const toContractForm = (contract: IEnterpriseContractListData) => {
        getVarsTrigger({ party_id: contract.company_party_id }).unwrap().then(res => {
            form.setFieldsValue({
                party_id: contract.company_party_id,
                vars: res.data.list
            });
            setContractFormModalForm({ isModalVisible: true });
        });
    }
    const [saveFormTrigger, saveFormResult] = useSaveEnterpriseContractVarsMutation();
    useEffect(() => {
        if (saveFormResult.isSuccess) {
            listTrigger(searchFormRef.current);
            onSaveFormCancel();
        }
    }, [saveFormResult]);
    const onSaveFormOk = async () => {
        await form.validateFields();
        let _form = form.getFieldsValue(true);

        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '请确定参数准确无误，提交后无法再修改！',
            onOk() {
                saveFormTrigger(_form);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    const onSaveFormCancel = () => {
        form.resetFields();
        setContractFormModalForm({ isModalVisible: false });
    }

    return (
        <>
            <div className='menu inner-page'>
                <div className='menu__title'>企业合同列表</div>

                <Radio.Group onChange={handleStatusChange} value={searchForm.nav_type} style={{ marginBottom: 8 }}>
                    <Radio.Button value={1}>发起方</Radio.Button>
                    <Radio.Button value={2}>受邀方</Radio.Button>
                </Radio.Group>

                {/* 顶部工作栏 */}
                <div className='search'>
                    {/* <Select
                        className="search__item"
                        allowClear
                        placeholder="签署状态"
                        value={searchForm.party_b_sign_status}
                        onChange={handleStatusChange}
                    >
                        {signStatusResult.data?.data.contract_sign_status?.options.map(option => (
                            <Option key={option.value}>{option.name}</Option>
                        ))}
                    </Select> */}

                    {
                        <Button
                            type="primary"
                            className="search__last-button"
                            icon={<SendOutlined />}
                            onClick={toSendContract}
                        // disabled={!fddAuth?.is_auth || !fddAuth?.auto_sign}
                        >
                            发起合同
                        </Button>
                    }

                </div>

                {/* 表格 */}
                <Table
                    dataSource={listData}
                    size='small'
                    loading={listResult.isLoading}
                    // rowSelection={
                    //     {
                    //         selectedRowKeys,
                    //         onChange: onSelectChange
                    //     }
                    // }
                    rowKey={(record: IEnterpriseContractListData) => record?.id}
                    pagination={{
                        position: ['bottomRight'],
                        size: "small",
                        total: listResult.data?.data.total ?? 0,
                        showTotal: total => `共 ${total} 项`,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        onChange: onPageChange
                    }}>
                    <Column title="合同名称" dataIndex="title" key="title" />
                    <Column
                        title="甲方"
                        key="party_a_type_text"
                        render={(_: any, record: IEnterpriseContractListData | undefined) => {
                            return <div>{record?.parties.find(party => party.party_type === 'a')?.party_name ?? '无'}</div>
                        }} />
                    <Column
                        title="甲方签署状态"
                        key="party_a_sign_status_text"
                        render={(_: any, record: IEnterpriseContractListData | undefined) => {
                            return <div>{record?.parties.find(party => party.party_type === 'a')?.sign_status_text ?? '无'}</div>
                        }}
                    />
                    <Column
                        title="乙方"
                        key="party_b_type_text"
                        render={(_: any, record: IEnterpriseContractListData | undefined) => {
                            return <div>{record?.parties.find(party => party.party_type === 'b')?.party_name ?? '无'}</div>
                        }} />
                    <Column
                        title="乙方签署状态"
                        key="party_b_sign_status_text"
                        render={(_: any, record: IEnterpriseContractListData | undefined) => {
                            return <div>{record?.parties.find(party => party.party_type === 'b')?.sign_status_text ?? '无'}</div>
                        }}
                    />
                    <Column
                        title="丙方"
                        key="party_c_type_text"
                        render={(_: any, record: IEnterpriseContractListData | undefined) => {
                            return <div>{record?.parties.find(party => party.party_type === 'c')?.party_name ?? '无'}</div>
                        }} />
                    <Column
                        title="丙方签署状态"
                        key="party_c_sign_status_text"
                        render={(_: any, record: IEnterpriseContractListData | undefined) => {
                            return <div>{record?.parties.find(party => party.party_type === 'c')?.sign_status_text ?? '无'}</div>
                        }}
                    />
                    <Column
                        title="丁方"
                        key="party_d_type_text"
                        render={(_: any, record: IEnterpriseContractListData | undefined) => {
                            return <div>{record?.parties.find(party => party.party_type === 'd')?.party_name ?? '无'}</div>
                        }} />
                    <Column
                        title="丁方签署状态"
                        key="party_d_sign_status_text"
                        render={(_: any, record: IEnterpriseContractListData | undefined) => {
                            return <div>{record?.parties.find(party => party.party_type === 'd')?.sign_status_text ?? '无'}</div>
                        }}
                    />
                    <Column
                        title="操作"
                        key="action"
                        render={(_: any, record: IEnterpriseContractListData | undefined) => (
                            <Space>
                                <Dropdown.Button trigger={['click', 'hover']} type="primary" loading={false} overlay={
                                    <Menu
                                        items={record ? searchForm.nav_type === 1 ? [
                                            {
                                                key: 'sign',
                                                disabled: !record.can_sign,
                                                label: (
                                                    <div>合同签署</div>
                                                ),
                                                onClick: () => toSign(record),
                                            },
                                            {
                                                key: 'form',
                                                disabled: record.parties.find(item => item.id === record.company_party_id)?.sign_status !== 11,
                                                label: (
                                                    <div>填写合同变量</div>
                                                ),
                                                onClick: () => toContractForm(record),
                                            },
                                            {
                                                key: 'revoke',
                                                disabled: !record.can_cancel,
                                                label: (
                                                    <div>撤销合同</div>
                                                ),
                                                onClick: () => toRevoke(record.id),
                                            }
                                        ] : [{
                                            key: 'sign',
                                            disabled: !record.can_sign,
                                            label: (
                                                <div>合同签署</div>
                                            ),
                                            onClick: () => toSign(record),
                                        },
                                        {
                                            key: 'form',
                                            disabled: record.parties.find(item => item.id === record.company_party_id)?.sign_status !== 11,
                                            label: (
                                                <div>填写合同变量</div>
                                            ),
                                            onClick: () => toContractForm(record),
                                        }] : []}
                                    />
                                }>
                                    操作
                                </Dropdown.Button>
                            </Space>
                        )}
                    />
                </Table>
            </div>

            {/* 下发合同弹窗 */}
            {
                sendContractModalForm
                    ? <SendContractModal
                        data={sendContractModalForm.data}
                        visible={sendContractModalForm.isModalVisible}
                        onFinish={() => {
                            setSendContractModalForm({
                                ...sendContractModalForm,
                                isModalVisible: false
                            })
                            listTrigger(searchFormRef.current);
                        }}
                        onCancel={() => {
                            setSendContractModalForm({
                                ...sendContractModalForm,
                                isModalVisible: false
                            })
                        }}
                    /> : <></>
            }

            <Modal
                title="填写合同参数"
                open={contractFormModalForm.isModalVisible}
                okText="提交"
                onOk={onSaveFormOk}
                onCancel={onSaveFormCancel}
                confirmLoading={saveFormResult.isLoading}
            >
                <Form form={form} layout="vertical" name="timeForm">
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) => prevValues.vars !== curValues.vars}
                    >
                        {({ getFieldValue }) => {
                            const vars: IEnterpriseContractVarItem[] = getFieldValue('vars') ?? [];
                            return (
                                <>
                                    {
                                        vars.map((item, index) => (
                                            <Form.Item
                                                label={item.desc}
                                                name={['vars', index, 'value']}
                                                rules={[{ required: true, message: `请输入${item.desc}` }]}
                                            >
                                                <Input placeholder={`请输入${item.desc}`} />
                                            </Form.Item>
                                        ))
                                    }
                                </>
                            );
                        }}
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default EnterpriseContractSendList;